<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos del operador"
      ref="pageForm"
      :items="items">
    </PageHeader>
    <ShowDetails
      :item="dataDetails"
      :items="betItems"
      :result="roundResult"
      :jackpots="jackpots"
      :plenoAmount="plenoAmount"
      ref="showDetails" />
    <show-dragon-bet ref="showDragonBet" :item="dataDetails" />
    <showBlackJackBet ref="showBlackJackBetComponent" :item="dataDetails" />
    <showWheelBet ref="showWheelBetComponent" :item="dataDetails" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <p>
                  En este apartado puedes revisar las transacciones de los
                  jugadores en los juegos.
                </p>
                <p>
                  Al descargar el excel verás los registros hasta el limie
                  marcado.
                </p>
              </Tooltip>
            </div>
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.from") }}: </span>
                  <vs-input v-model="filterData.fromDate" type="date" />
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.fromHour"
                    v-if="filterData.fromDate" />
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.until") }}: </span>
                  <vs-input v-model="filterData.toDate" type="date" />
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.toHour"
                    v-if="filterData.toDate" />
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" v-if="checkAdmins()">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.client") }}:
                  <select
                    v-model="filterData.clientId"
                    @change="getOperatorClient(true)"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option
                      v-for="(item, index) in clients"
                      :key="index"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div
                class="col-sm-12 col-md-4"
                v-if="userRole !== 'OPERATOR' && filterData.clientId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.operator") }}:
                  <select
                    v-model="filterData.operatorId"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option
                      v-for="(item, index) in operators"
                      :key="index"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.operatorId">
                <label class="d-inline-flex align-items-center w-100">
                  WL:
                  <select
                    v-model="filterData.wl"
                    name="wl"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option
                      v-for="(item, index) in mapWls"
                      :key="index"
                      :value="item">
                      {{ item }}
                    </option>
                  </select>
                </label>
              </div>
              <div
                class="col-sm-12 col-md-12 d-flex justify-content-start"
                v-if="userRole !== 'OPERATOR'"
                @change="getGames">
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content">
                  {{ $t("filter.gameType") }}:
                </label>
                <select
                  v-model="filterData.gameType"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione..">
                  <option disabled selected>
                    {{ $t("helpers.select") }}...
                  </option>
                  <option
                    v-for="(item, index) in mapGamesTypes"
                    :key="index"
                    :value="item.value">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.gameType">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("sidebar.games.text") }}:
                  <select
                    v-model="filterData.rouletteId"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option value="*">ALL</option>
                    <option
                      v-for="(item, index) in roulettes"
                      :key="index"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div
                class="col-sm-12 col-md-4"
                v-if="filterData.gameType === gamesTypes.ExternalGames">
                <b-col sm="12" md="12">
                  <vs-checkbox v-model="oldTransactions">
                    TRANSACCIONES ANTIGUAS
                  </vs-checkbox>
                </b-col>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.rouletteId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.currency") }}:
                  <select
                    v-model="filterData.currency"
                    name="currency"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option value="*">ALL</option>
                    <option
                      v-for="item in currencies"
                      :key="item._id"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <vs-input
                placeholder="TRANSACTION ID"
                border
                class="shadow-lg me-2"
                v-model="filterData.transactionId" />
              <vs-input
                placeholder="USER ID"
                border
                class="shadow-lg me-2"
                v-model="filterData.playerId" />
              <vs-input
                placeholder="ROUND ID"
                border
                class="shadow-lg me-2"
                v-model="filterData.identifierNumber" />
              <CreditDebitSelect
                @update:typeTransaction="updateTypetransaction"
                ref="creditDebitSelectComponent" />
              <vs-button>
                <downloadexcel
                  :fetch="exportData"
                  :fields="json_fields"
                  :name="fileName"
                  type="xls">
                  {{ $t("filter.downloadExcel") }}
                </downloadexcel>
              </vs-button>

              <DateRange @handleRadio="handleRadio" />
              <vs-button type="button" @click="filtrar()">
                {{ $t("filter.filter") }}
              </vs-button>
              <vs-button
                type="button"
                success
                @click="cancelFilter()"
                :disabled="!Object.entries(filterData).length">
                {{ $t("filter.cancel") }}
              </vs-button>
            </div>
            <div class="text-center" v-if="fetching">
              <b-spinner type="grow" label="Spinning"></b-spinner>
              {{ $t("helpers.loading") }}...
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6 d-inline-flex align-items-center">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center m-0">
                    {{ $t("filter.show") }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t("filter.entries") }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t("filter.search") }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      primary
                      class="shadow-lg">
                    </vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
              <!-- <totalRegister :totalRegister="totalRegister" /> -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0" v-if="tableData.length">
                <b-table
                  id="transaction-table"
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc">
                  <template #cell(game)="data">
                    {{
                      typeof data.item.game === "string"
                        ? data.item.game
                        : data.item.game.name
                    }}
                  </template>
                  <template #cell(userBalance)="data">
                    {{
                      typeof data.item.userBalance === "number"
                        ? formatNumber(data.item.userBalance)
                        : "Transacción sin balance"
                    }}
                  </template>
                  <template #cell(credit)="data">
                    {{
                      ["credit", "rollback"].includes(data.item.type)
                        ? formatNumber(data.item.amount)
                        : 0
                    }}
                  </template>
                  <template #cell(debit)="data">
                    {{
                      data.item.type === "debit"
                        ? formatNumber(data.item.amount)
                        : 0
                    }}
                  </template>
                  <template #cell(createdAt)="data">
                    <div
                      class="createdAt"
                      @mouseover="handleOver"
                      @mouseleave="handleLeave">
                      {{
                        moment(data.item.createdAt).format(
                          "DD/MM/YYYY | HH:mm:ss"
                        )
                      }}
                      <span class="badge badge-time bg-primary rounded-pill"
                        >Server time:
                        {{
                          moment
                            .utc(data.item.createdAt)
                            .format("DD/MM/YYYY | HH:mm:ss")
                        }}</span
                      >
                    </div>
                  </template>
                  <template #cell(errorRound)="data">
                    {{
                      data.item.providerresult ? data.item.providerresult : 0
                    }}
                  </template>
                  <template #cell(rollbackRound)="data">
                    {{ data.item.round ? data.item.round.error : "0" }}
                  </template>
                  <template #cell(retryRound)="data">
                    {{ data.item.round ? data.item.round.retry : "0" }}
                  </template>
                  <template #cell(details)="data">
                    <div>
                      <vs-button @click="openDetails(data.item)">{{
                        $t("helpers.look")
                      }}</vs-button>
                    </div>
                  </template>
                  <template #cell(rateUSD)="data">
                    {{
                      data.item.currencyExchangeDollar
                        ? data.item.currencyExchangeDollar
                        : "NO EXCHANGE"
                    }}
                  </template>
                  <template #cell(betUSD)="data">
                    {{
                      data.item.amountExchangeDollar
                        ? data.item.type === "debit"
                          ? data.item.amountExchangeDollar.toFixed(4)
                          : 0
                        : "NO EXCHANGE"
                    }}
                  </template>
                  <template #cell(winUSD)="data">
                    {{
                      data.item.amountExchangeDollar
                        ? data.item.type === "credit"
                          ? data.item.amountExchangeDollar.toFixed(4)
                          : 0
                        : "NO EXCHANGE"
                    }}
                  </template>
                  <template #cell(playerIp)="data">
                    {{ data.item.type === "credit" ? "-" : data.item.playerIp }}
                  </template>
                  <template #cell(platform)="data">
                    {{ data.item.type === "credit" ? "-" : data.item.platform }}
                  </template>
                  <template #cell(playerCountry)="data">
                    {{
                      data.item.type === "credit"
                        ? "-"
                        : data.item.playerCountry
                    }}
                  </template>
                  <template #cell(userAgent)="data">
                    {{
                      data.item.type === "credit" ? "-" : data.item.userAgent
                    }}
                  </template>
                </b-table>
                <!-- <div v-if="!totalRegister" class="text-center">
                  {{ $t('tableReports.noResults') }}
                </div> -->
              </div>
            </b-overlay>
            <p
              class="text-center my-2"
              v-if="searchActive && !tableData.length && !loading">
              {{ $t("tableReports.noResults") }}
            </p>
            <div class="row" v-if="tableData.length">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="
                        tableData.length > limit || tableData.length < limit
                      "></button>
                  </ul>
                  <!-- <pagination :totalPages="totalPages" :currentPage="currentPage"></pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header-simple";
import downloadexcel from "vue-json-excel";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import ShowDetails from "./show.vue";
import { breadCrumbData } from "../../../../helpers/breadcrumbs/breadCrumbs";
// import moment from "moment";
import Tooltip from "../../../../components/tooltip.vue";
import DateRange from "../components/DateRange.vue";
import CreditDebitSelect from "../components/CreditDebitSelect.vue";
import { onlyAdmins } from "../../../../router/guards/roles";
import { gameList } from "../../../../helpers/gameList";
import { globalFields, externalGamesFields } from "@/state/fields";
import { mapActions, mapGetters } from "vuex";
import { formatExportTransaction } from "@/state/helpers/index";
import { makeFieldsExternal, makeFieldsRoulette } from "../helpers/jsonFields";
import ShowDragonBet from "./show-dragon-bet.vue";
import { showBlackJackBet, showWheelBet } from "./components";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Operators Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ShowDetails,
    downloadexcel,
    // pagination,
    // totalRegister,
    Tooltip,
    DateRange,
    ShowDragonBet,
    showBlackJackBet,
    showWheelBet,
    CreditDebitSelect,
  },
  data() {
    return {
      oldTransactions: false,
      searchActive: false,
      fetching: false,
      //Paginacion
      skip: 0,
      limit: 10,
      page: 0,
      // disabledNextPage: null,
      // disabledPreviousPage: null,
      fileName: "Transacciones.xls",
      json_fields: {},
      tableData: [],

      title: this.$t("sidebar.reports.subItems.transactions"),
      items: breadCrumbData.transactions,
      loading: false,
      filterData: {},
      dataDetails: {},
      betItems: [],
      totalRows: 1,
      currentPage: 1,
      totalPages: "...",
      perPage: 10,
      pageOptions: [10, 25, 50, 100, "ALL"],
      filter: null,
      filterOn: ["roundId"],
      clients: [],
      operators: [],
      roulettes: [],
      currencies: [],
      sortBy: "createdAt",
      sortDesc: true,
      // FIELDS
      fields: [],
      //
      typeform: {
        name: "",
        minBet: "",
        maxBet: "",
        client: "",
      },
      clientFilter: null,
      // totalRegister: 0,
      roundResult: 0,
      jackpots: [],
      plenoAmount: 0,
      userRole: "",
      userData: "",
      // downloadSelected: "true",
      gameList,
      gameType: null,

      // ROLES
      readAllRoles: onlyAdmins,
      grouping: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    ...mapGetters({
      mapGames: "games/getGames",
      mapTransactions: "reports/getData",
      mapClients: "clients/getClients",
      mapOperators: "operators/getOperators",
      mapCurrencies: "currencies/getCurrencies",
      mapClientRoulettes: "clients/getClientRoulettes",
      mapOperatorRoulettes: "operators/getRoulettes",
      getBet: "reports/getBet",
      getResult: "reports/getResult",
      mapFields: "reports/getFields",
      mapEarnings: "reports/getBetEarnings",
      mapWls: "reports/getWls",
      mapGamesTypes: "reports/getGamesTypes",
      gamesTypes: "reports/gamesTypes",
      formatNumber: "reports/formatNumbers",
      getType: "reports/getType",
      isSuccess: "reports/isSuccess",
      getGroupedCount: "reports/getGroupedCount",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    if (Object.keys(this.$route.query).length) {
      for (let key in this.$route.query) {
        this.filterData[key] = this.$route.query[key];
      }
    }

    this.getUserRole();
    this.getCurrencies();
  },
  methods: {
    checkAdmins() {
      return this.readAllRoles.includes(this.userRole);
    },
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.rol;

      if (this.userRole === "CLIENT") {
        this.filterData.clientId = this.userData.client;
        this.getOperatorClient();
        this.getRoulettesClient();
      } else if (this.userRole === "OPERATOR") {
        this.filterData.operatorId = this.userData.operator;
        this.getRoulettesOperator();
      } else {
        this.getClients();
        this.getOperator();
      }

      if (!Object.entries(this.$route.query).length) return;

      this.filtrar();
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "visible";
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "hidden";
      }
    },
    async getClients() {
      try {
        await this.fetchClients();
        this.clients = this.mapClients;
      } catch (error) {
        console.log(error);
      }
    },
    async getOperator() {
      try {
        const options = {};
        await this.fetchOperators({ options });
        this.operators = this.mapOperators;
      } catch (error) {
        console.log(error);
      }
    },
    async filtrar() {
      this.searchActive = true;
      this.loading = true;

      const options = {
        params: {
          ...this.filterData,
          limit: this.limit,
          page: this.currentPage,
        },
      };

      try {
        await this.fetchTransactions({
          gameType: this.filterData.gameType,
          options,
          oldTransactions: this.oldTransactions,
        });

        this.tableData = this.mapTransactions;
        this.fields = this.mapFields;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getTransactionsExternalGames() {
      try {
        const options = {
          params: {
            ...this.filterData,
            limit: this.limit,
            page: this.currentPage,
          },
        };

        if (this.oldTransactions) {
          await this.fetchExternalGamesTransactions({
            path: "external-games/all-transactions-old",
            options,
            type: "data",
          });
        } else {
          await this.fetchExternalGamesTransactions({
            path: "external-games/all-transactions",
            options,
            type: "data",
          });
        }

        this.makeFields([...externalGamesFields, ...globalFields]);
        this.tableData = this.mapTransactions;
      } catch (error) {
        console.log("ERROR GET TRANSACTIONS EXTERNAL GAMES", error);
      }

      this.loading = false;
    },
    makeFields(data) {
      this.fields = [...data];
    },
    cancelFilter() {
      this.filterData = {};
      this.currentPage = 1;
      this.searchActive = false;
      this.$refs.creditDebitSelectComponent.value = [];
      this.getUserRole();
    },
    async groupTransactions() {
      this.grouping = true;

      try {
        await this.groupTransactionsProfit({
          ...this.filterData,
          limit: this.limit,
          page: this.currentPage,
        });

        if (!this.isSuccess)
          throw {
            title: "Error agrupando",
            icon: "error",
          };

        if (!this.getGroupedCount)
          throw {
            title: "No hay transacciones por agrupar",
            icon: "info",
          };

        Swal.fire({
          title: "Transacciones agrupadas",
          text: `# ${this.getGroupedCount} agrupadas`,
          icon: "success",
          timer: 2000,
        });

        this.grouping = false;
      } catch (error) {
        console.log("ERORR GROUPING", error);

        const { title, icon } = error;
        this.grouping = false;

        Swal.fire({
          title,
          icon,
          timer: 2000,
        });
      }
    },
    async getCurrencies() {
      try {
        await this.fetchCurrencies({
          role: this.userData.role.name,
          user: this.userData,
        });
        this.currencies = this.mapCurrencies;
      } catch (err) {
        console.log(err);
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.filtrar();
    },
    daysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    async handleRadio(value) {
      this.picked = value;
      switch (this.picked) {
        case this.$t("helpers.onPage"):
          break;
        case this.$t("helpers.today"): {
          const initialDate = new Date().toJSON().split("T")[0];
          this.filterData = {
            ...this.filterData,
            fromDate: initialDate,
            toDate: initialDate,
          };
          break;
        }

        case this.$t("helpers.month"): {
          let from = 1;
          let to = this.daysInMonth(
            new Date().getMonth(),
            new Date().getFullYear()
          );
          const initialDate = new Date().toJSON().split("T")[0].split("-");
          let fromDate = [initialDate[0], initialDate[1], from].join("-");
          let toDate = [initialDate[0], initialDate[1], to].join("-");
          this.filterData = { ...this.filterData, fromDate, toDate };
          break;
        }
        default:
          break;
      }
      return true;
    },
    async exportData() {
      if (!this.filterData.fromDate || !this.filterData.toDate)
        return Swal.fire(
          "Es necesario seleccionar un rango de fecha",
          "",
          "info"
        );

      this.fetching = true;

      const options = {
        params: {
          ...this.filterData,
          limit: 1000,
          page: 1,
        },
      };

      if (this.filterData.gameType === this.gamesTypes.ExternalGames)
        return this.exportTransactionsExternalGames(options);

      await this.fetchExternalGamesTransactions({
        path: "transactions",
        type: "transactions",
        options,
      });

      if (this.mapTransactions.length) {
        const formattedData = formatExportTransaction(this.mapTransactions);

        this.fetching = false;
        this.json_fields = makeFieldsRoulette();

        return formattedData;
      } else {
        Swal.fire({
          position: "center",
          icon: "info",
          title: "No se encuentran datos asociados",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      this.fetching = false;
    },
    async exportTransactionsExternalGames(options) {
      if (this.oldTransactions) {
        await this.fetchExternalGamesTransactions({
          path: "external-games/all-transactions-old",
          options,
          type: "data",
        });
      } else {
        await this.fetchExternalGamesTransactions({
          path: "external-games/all-transactions",
          options,
          type: "data",
        });
      }

      const formatted = this.mapTransactions.map((el) => {
        return {
          ...el,
          game:
            typeof el.game === "string"
              ? this.roulettes.find(
                  (game) => game._id === el.game || game.productId === el.game
                ).name
              : el.game.name,
          createdAt: new Date(el.game.createdAt),
        };
      });

      this.fetching = false;

      this.json_fields = makeFieldsExternal();
      return formatted;
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: "",
        minBet: "",
        maxBet: "",
        client: "",
      };
      this.filtrar();
    },
    async openDetails(item) {
      const type = this.getType(this.filterData.gameType, item);

      switch (type) {
        case this.gamesTypes.Roulette:
          await this.openRouletteBet(item);
          break;
        case this.gamesTypes.DragonTiger:
          await this.openDragonBet(item);
          break;
        case this.gamesTypes.BlackJack:
          await this.openBlackJackBet(item);
          break;
        case this.gamesTypes.WheelFortune:
          await this.openWheelBet(item);
          break;

        default:
          break;
      }
    },
    async openRouletteBet(item) {
      if (!this.$refs.showDetails.modal) {
        this.betItems = [];
        this.jackpots = [];
        this.plenoAmount = 0;
      }

      this.jackpots = item.round.jackpot_values;
      this.plenoAmount = item.pleno;

      if (item.bet) {
        this.dataDetails = item;
        try {
          await this.fechBetDetail({ _id: item.bet._id, type: item.type });
          this.bets = [];

          this.betItems = this.getBet;
          this.roundResult = this.getResult;
        } catch (err) {
          console.log(err);
        }
      }
      this.$refs.showDetails.modal = true;
    },
    async openDragonBet(item) {
      await this.fetchDragonBet({ item });
      this.dataDetails = {
        ...item,
        betDetail: this.getBet,
        earnings: this.mapEarnings,
        gameType: this.filterData.gameType,
      };

      this.$refs.showDragonBet.modal = true;
    },
    async openBlackJackBet(item) {
      await this.fetchBlackJackBet({ item });

      this.dataDetails = {
        ...item,
        gameType: this.filterData.gameType,
        bet: this.getBet,
      };

      this.$refs.showBlackJackBetComponent.modal = true;
    },
    async openWheelBet(item) {
      await this.fetchWheelBet({ item });

      this.dataDetails = {
        ...item,
        gameType: this.getType(this.filterData.gameType, item),
      };

      console.log("this.dataDetails", this.dataDetails);

      this.$refs.showWheelBetComponent.modal = true;
    },
    change() {
      this.filtrar();
    },
    async getOperatorClient(filter) {
      const clientId = filter ? this.filterData.clientId : this.userData.client;
      try {
        const options = { params: { client: clientId } };
        await this.fetchOperators({ options });
        this.operators = this.mapOperators;
      } catch (err) {
        console.log("Error al obtener operadores del cliente", err);
      }
    },
    async getRoulettesClient() {
      const client = this.userData.client;
      try {
        await this.fetchClientRoulettes({ id: client });
        if (!this.mapClientRoulettes.length) return (this.roulettes = []);

        const formatData = this.mapClientRoulettes.map((element) => {
          return element.roulette;
        });
        this.roulettes = formatData;
      } catch (err) {
        console.log("Err", err);
      }
    },
    async getRoulettesOperator() {
      const operatorId = this.userData.operator;
      try {
        await this.fetchOperatorRoulettes({ id: operatorId });
        const formattedData = this.mapOperatorRoulettes.map((element) => {
          return element.roulette;
        });
        this.roulettes = formattedData;
      } catch (err) {
        console.log("Error al obtener ruletas del operador", err);
      }
    },
    async getGames() {
      try {
        if (this.userRole === "CLIENT") {
          this.getRoulettesClient();
        } else if (this.userRole === "OPERATOR") {
          this.getRoulettesOperator();
        } else {
          await this.fetchGames({ gameType: this.filterData.gameType });
          this.roulettes = this.mapGames;
        }
      } catch (error) {
        console.log("ERROR GET GAMES", error);
      }
    },
    updateTypetransaction(data) {
      this.filterData = { ...this.filterData, ...data };

      const { amount } = this.filterData;

      this.sortBy = amount ? "amount" : "createdAt";
    },
    ...mapActions({
      fetchGames: "games/fetchGames",
      fetchTransactions: "reports/fetchReports",
      fetchExternalGamesTransactions: "reports/fetchTransactions",
      fetchClients: "clients/fetchClients",
      fetchOperators: "operators/fetchOperators",
      fetchCurrencies: "currencies/fetchCurrencies",
      fetchClientRoulettes: "clients/fetchClientRoulettes",
      fetchOperatorRoulettes: "operators/getOperatorRoulettes",
      fechBetDetail: "reports/fechBetDetail",
      fetchDragonBet: "reports/fetchDragonBet",
      fetchBlackJackBet: "reports/fetchBlackJackBet",
      fetchWheelBet: "reports/fetchWheelBet",
      fetchWls: "reports/fetchWls",
      groupTransactionsProfit: "reports/groupTransactionsProfit",
    }),
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.filtrar();
    },
    "filterData.operatorId": async function (operator) {
      await this.fetchWls({ operator });
    },
    "filterData.fromHour": function (hour) {
      if (!hour) return (this.filterData.fromDateHour = "");
      this.filterData.fromDateHour = `${this.filterData.fromDate} ${hour}`;
    },
    "filterData.toHour": function (hour) {
      if (!hour) return (this.filterData.toDateHour = "");
      this.filterData.toDateHour = `${this.filterData.toDate} ${hour}`;
    },
  },
  middleware: "authentication",
};
</script>

<style>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  left: 0;
  top: -12px;
  visibility: hidden;
}

.space-nowrap {
  white-space: nowrap !important;
}
</style>
